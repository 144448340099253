// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../src/pages/App.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-home-inner-tsx": () => import("./../../src/pages/HomeInner.tsx" /* webpackChunkName: "component---src-pages-home-inner-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../src/pages/Home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-tsx": () => import("./../../src/pages/Mobile.tsx" /* webpackChunkName: "component---src-pages-mobile-tsx" */),
  "component---src-pages-projects-clog-tsx": () => import("./../../src/pages/projects/Clog.tsx" /* webpackChunkName: "component---src-pages-projects-clog-tsx" */),
  "component---src-pages-projects-data-tourism-tsx": () => import("./../../src/pages/projects/dataTourism.tsx" /* webpackChunkName: "component---src-pages-projects-data-tourism-tsx" */),
  "component---src-pages-projects-google-tvm-tsx": () => import("./../../src/pages/projects/GoogleTVM.tsx" /* webpackChunkName: "component---src-pages-projects-google-tvm-tsx" */),
  "component---src-pages-projects-pansaari-tsx": () => import("./../../src/pages/projects/Pansaari.tsx" /* webpackChunkName: "component---src-pages-projects-pansaari-tsx" */),
  "component---src-pages-projects-project-container-tsx": () => import("./../../src/pages/projects/ProjectContainer.tsx" /* webpackChunkName: "component---src-pages-projects-project-container-tsx" */),
  "component---src-pages-projects-project-image-tsx": () => import("./../../src/pages/projects/ProjectImage.tsx" /* webpackChunkName: "component---src-pages-projects-project-image-tsx" */),
  "component---src-pages-projects-project-screen-tsx": () => import("./../../src/pages/projects/ProjectScreen.tsx" /* webpackChunkName: "component---src-pages-projects-project-screen-tsx" */),
  "component---src-pages-projects-project-tsx": () => import("./../../src/pages/projects/Project.tsx" /* webpackChunkName: "component---src-pages-projects-project-tsx" */),
  "component---src-pages-projects-transcription-hub-tsx": () => import("./../../src/pages/projects/TranscriptionHub.tsx" /* webpackChunkName: "component---src-pages-projects-transcription-hub-tsx" */),
  "component---src-pages-projects-venga-tsx": () => import("./../../src/pages/projects/Venga.tsx" /* webpackChunkName: "component---src-pages-projects-venga-tsx" */),
  "component---src-pages-projects-views-background-tsx": () => import("./../../src/pages/projects/views/Background.tsx" /* webpackChunkName: "component---src-pages-projects-views-background-tsx" */),
  "component---src-pages-projects-virion-tsx": () => import("./../../src/pages/projects/Virion.tsx" /* webpackChunkName: "component---src-pages-projects-virion-tsx" */)
}

